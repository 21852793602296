import Component from 'vue-class-component';
import { invoiceService, bookingService } from '@/main';
import PageRender from '../../models/PageRender';
import { Invoice } from '../../models/Invoice';
import { AxiosResponse } from 'axios';
import to from 'await-to-js';
import { Transaction } from '../../models/Transaction';
import moment from 'moment/min/moment.min.js';

@Component
export default class IdealPage extends PageRender {
    public invoice: Invoice;
    public booking: any;
    public partiallyPaid: boolean = false;
    public invoicePaymentLinkId: string;
    public isLoading: boolean = true;
    public transaction: Transaction = null;
    public randomNumber: number = 0;
    public fullPayment: boolean = null;
    public fromPsp: boolean = false;
    public invalidLink: boolean = false;

    public async mounted() {
        this.invoicePaymentLinkId = this.$route.params.invoicePaymentLinkId;
        this.invoice = await this.getInvoice();

        if (!this.invoice || !this.invoice.invoiceId || this.invoice.invoiceId === 0) {
            this.invalidLink = true;
            this.isLoading = false;
            return;
        }

        this.booking = await this.getBooking();

        this.fromPsp = typeof this.$route.query.from_psp !== 'undefined';

        if ((this.invoice && this.invoice.status === 4) || (this.invoice && this.invoice.status === 3 && this.fromPsp)) {
            this.transaction = await this.getTransaction();
        }

        if (this.transaction && this.transaction.status === 'paid') {
            this.randomNumber = Math.floor(Math.random() * 5) + 1;
        }

        if ((this.invoice.status === 3 && this.invoice.totalAmount - this.invoice.amountPaid > 0) || this.isBookingWithin6Weeks()) {
            this.partiallyPaid = true;
            this.fullPayment = true;
        }

        this.isLoading = false;
    }

    public async getBooking() {
        const [err, response] = await to(bookingService.getBookingByInvoicePaymentLinkId(this.invoicePaymentLinkId));
        if (err) {
            return;
        }
        return response.data;
    }

    public async getInvoice() {
        const [err, response] = await to<AxiosResponse<Invoice>>(invoiceService.getInvoiceByInvoicePaymentLinkId(this.invoicePaymentLinkId));
        if (err) {
            // this.showError('Mislukt om je rekening op te halen');
            return new Invoice();
        }
        return response.data;
    }

    public async getTransaction() {
        const [err, response] = await to<AxiosResponse>(invoiceService.getTransactionByInvoiceId(this.invoice.invoiceId));
        if (err) {
            return;
        }

        return response.data;
    }

    public async startPayment() {
        this.showPending('Betaling wordt gestart..');
        const [err, response] = await to<AxiosResponse>(
            invoiceService.createTransaction(this.invoice.invoiceId, this.invoicePaymentLinkId, this.fullPayment),
        );
        if (err) {
            this.clearAndShowError('Mislukt om je door te sturen naar onze Idealprovider.', err);
        }

        const payment = response.data;
        window.location = payment.paymentUrl;
    }

    public getPrice() {
        return kendo.toString(this.invoice.totalAmount - this.invoice.amountPaid, 'c');
    }

    public getAlreadyPaidPrice() {
        return kendo.toString(this.invoice.amountPaid, 'c');
    }

    public getAanbetalingPrice() {
        return kendo.toString((this.invoice.totalAmount / 100) * 10, 'c');
    }

    public isBookingWithin6Weeks() {
        if (!this.booking) {
            return true;
        }

        if (this.invoice.status === 5) {
            return true;
        }

        if (this.booking.site) {
            const arrivalDate = moment(this.booking.arrivalDate, 'YYYY-MM-DD');
            const diff = arrivalDate.diff(moment(), 'days');
            if (diff <= 6 * 7) {
                return true;
            }
        }

        return false;
    }
}
